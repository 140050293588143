<template>
  <div>
    <KTCard ref="preview" v-bind:title="title">
      <!--页面标题-->
      <template v-slot:title>
        <h3 class="card-title">
          <b-icon icon="blockquote-right" class="mx-3"></b-icon>
          用户管理
        </h3>
      </template>

      <!--操作按钮组-->
      <template v-slot:toolbar>
        <div class="example-tools justify-content-center">
          <!--按钮组-->
          <b-button variant="primary" @click="create" class="mx-2">
            <b-icon icon="plus"></b-icon>
            新增
          </b-button>
          <b-button variant="success" @click="edit()" class="mx-2">
            <b-icon icon="pencil"></b-icon>
            编辑
          </b-button>
          <b-button variant="danger" @click="del()" class="mx-2">
            <b-icon icon="x"></b-icon>
            删除
          </b-button>
          <!--按钮组-->
        </div>
      </template>

      <!--数据表格-->
      <template v-slot:body>
        <div style="min-height: 70vh">
          <!--表格-->
          <v-data-table
            show-select
            fixed-header
            dense
            height="70vh"
            v-model="table.selected"
            :headers="table.headers"
            :items="table.desserts"
            :single-select="table.singleSelect"
            :footer-props="table.footer"
            :server-items-length="table.itemsCount"
            :options.sync="table.pagination"
            :no-data-text="table.noDataText"
            :loading="table.loading"
            :loading-text="table.loadingText"
            item-key="userId"
          >
            <template v-slot:item.userType="{ item }">
              <h6>
                <b-badge variant="success" v-if="item.userType == 1"
                  >平台用户</b-badge
                >
                <b-badge variant="warning" v-else-if="item.userType == 2"
                  >商户用户</b-badge
                >
              </h6>
            </template>

            <template v-slot:item.status="{ item }">
              <h5>
                <b-badge variant="success" v-if="item.status == 1"
                  >正常</b-badge
                >
                <b-badge variant="danger" v-else>锁定</b-badge>
              </h5>
            </template>

            <!--状态字段   格式处理-->
            <template v-slot:item.action="{ item }">
              <v-btn
                small
                text
                icon
                fab
                v-b-tooltip.hover.lefttop
                title="编辑用户"
                v-on:click="edit(item.userId)"
              >
                <v-icon>la la-edit</v-icon>
              </v-btn>
              <v-btn
                small
                text
                icon
                fab
                v-b-tooltip.hover.lefttop
                title="删除用户"
                v-on:click="del(item.userId)"
              >
                <v-icon>la la-trash</v-icon>
              </v-btn>
              <v-btn small text icon fab>
                <b-dropdown
                  size="sm"
                  variant="link"
                  toggle-class="custom-v-dropdown btn  btn-sm"
                  no-caret
                  left
                  no-flip
                  v-b-tooltip.hover.lefttop
                  title="更多操作"
                >
                  <template v-slot:button-content>
                    <v-icon>la la-ellipsis-h</v-icon>
                  </template>
                  <b-dropdown-item v-on:click="changeStatus(item.userId, 0)">
                    <v-icon>icon-xl la la-lock</v-icon>
                    锁定
                  </b-dropdown-item>
                  <b-dropdown-item v-on:click="changeStatus(item.userId, 1)">
                    <v-icon>icon-xl la la-lock-open</v-icon>
                    解锁
                  </b-dropdown-item>
                  <b-dropdown-item v-on:click="resetPassword(item.userId)">
                    <v-icon>icon-xl la la-key</v-icon>
                    重置密码
                  </b-dropdown-item>
                </b-dropdown>
              </v-btn>
            </template>

            <!--表格筛选-->
            <template v-slot:top>
              <b-form inline>
                <b-form-group>
                  <b-form-select
                    :options="filter.conditions"
                    v-model="filter.field"
                  ></b-form-select>
                </b-form-group>
                <label
                  class="after-label ml-2 pb-md-2 pb-lg-2 pb-xl-2 pb-sm-3 pb-3"
                  >:</label
                >
                <b-form-group class="mx-2">
                  <b-form-input
                    id="input-2"
                    v-model="filter.keyword"
                    placeholder="检索关键词"
                  ></b-form-input>
                </b-form-group>

                <b-form-group class="mx-2 ml-md-2 ml-lg-2 ml-xl-2 ml-sm-0 ml-0">
                  <b-form-select
                    :options="filter.utOptions"
                    v-model="filter.userType"
                  ></b-form-select>
                </b-form-group>

                <b-form-group class="mx-2">
                  <b-form-select
                    :options="filter.statusOptions"
                    v-model="filter.status"
                  ></b-form-select>
                </b-form-group>
                <b-form-group class="mx-2">
                  <b-button variant="success" v-on:click="getData()">
                    <v-icon small> flaticon-search </v-icon>
                    查询
                  </b-button>
                </b-form-group>
              </b-form>

              <!--分隔线-->
              <v-divider></v-divider>
            </template>
          </v-data-table>
        </div>
      </template>
    </KTCard>

    <!--模态框-数据表单-->
    <b-modal ref="my-modal" size="lg" centered title="新增用户" static="true">
      <b-container fluid>
        <b-form
          id="userForm"
          method="POST"
          class="form"
          novalidate="novalidate"
        >
          <b-row>
            <b-col cols="6">
              <b-form-group label="用户名:">
                <b-form-input
                  type="text"
                  name="userName"
                  v-model="formModel.userName"
                  placeholder="请输入用户名..."
                ></b-form-input>
              </b-form-group>
              <b-form-group label="用户类型:">
                <b-form-select
                  :options="userType"
                  name="userType"
                  v-model="formModel.userType"
                ></b-form-select>
              </b-form-group>
              <b-form-group label="手机号码:">
                <b-form-input
                  type="text"
                  name="phone"
                  v-model="formModel.phone"
                  placeholder="请输入手机号码..."
                ></b-form-input>
              </b-form-group>
              <b-form-group label="QQ号码:">
                <b-form-input
                  type="text"
                  name="qq"
                  v-model="formModel.qq"
                  placeholder="请输入QQ号码..."
                ></b-form-input>
              </b-form-group>
              <b-form-group label="初始密码:">
                <b-form-input
                  type="password"
                  name="password"
                  v-model="formModel.password"
                  placeholder="请输入初始密码..."
                ></b-form-input>
              </b-form-group>
            </b-col>

            <b-col cols="6">
              <b-form-group label="真实姓名:">
                <b-form-input
                  type="text"
                  name="realName"
                  v-model="formModel.realName"
                  placeholder="请输入真实姓名..."
                ></b-form-input>
              </b-form-group>
              <b-form-group label="用户角色:">
                <RoleSelect
                  :select.sync="roleSelected"
                  ref="roleSelect"
                ></RoleSelect>
                <!-- <b-form-input type="email" required placeholder="Enter email"></b-form-input>-->
              </b-form-group>
              <b-form-group label="电子邮箱:">
                <b-form-input
                  type="email"
                  name="email"
                  v-model="formModel.email"
                  placeholder="请输入电子邮箱..."
                ></b-form-input>
              </b-form-group>
              <b-form-group label="微信号码:">
                <b-form-input
                  type="text"
                  name="email"
                  v-model="formModel.wechat"
                  placeholder="请输入微信号码..."
                ></b-form-input>
              </b-form-group>
              <b-form-group label="初始状态:">
                <b-form-radio-group
                  size="lg"
                  name="status"
                  v-model="formModel.status"
                  style="height: 38px !important; line-height: 30px !important"
                >
                  <b-form-radio value="1">正常</b-form-radio>
                  <b-form-radio value="0">锁定</b-form-radio>
                </b-form-radio-group>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </b-container>
      <!--表格筛选-->
      <template v-slot:modal-footer>
        <b-button v-on:click="$refs['my-modal'].hide()" variant="danger"
          >取消</b-button
        >
        <b-button v-on:click="onSubmit" variant="primary">保存</b-button>
      </template>
    </b-modal>
    <!--模态框-数据表单-->
  </div>
</template>

<script>
import KTCard from "@/view/content/Card.vue";
import KTUtil from "@/assets/js/components/util";

import ApiService from "@/core/services/api.service";

// FormValidation plugins
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import Swal from "sweetalert2";
import RoleSelect from "@/view/pages/oms_pages/user/RoleSelect";

export default {
  name: "List",
  components: {
    KTCard,
    RoleSelect,
  },
  props: {
    title: String,
  },
  data() {
    return {
      filter: {
        conditions: [
          { value: null, text: "检索条件" },
          { value: "userName", text: "用户名" },
          { value: "realName", text: "真实姓名" },
          { value: "phone", text: "手机号码" },
          { value: "email", text: "电子邮箱" },
        ],
        utOptions: [
          { value: null, text: "用户类型" },
          { value: 1, text: "平台用户" },
          { value: 2, text: "商户用户" },
        ],
        statusOptions: [
          { value: null, text: "状态" },
          { value: 0, text: "锁定" },
          { value: 1, text: "正常" },
        ],
        field: null,
        keyword: "",
        userType: null,
        status: null,
      },
      userType: [
        { value: 1, text: "平台用户", color: "primary" },
        { value: 2, text: "会员用户", color: "warning" },
        { value: 3, text: "供货商用户", color: "success" },
      ],
      roleSelected: [],
      submitUrl: "",
      formModel: {
        userType: 1,
        userName: "",
        realName: "",
        password: "",
        phone: "",
        qq: "",
        wechat: "",
        email: "",
        roles: [],
        status: "",
      },
      table: {
        loadingText: "正在加载...",
        noDataText: "暂无数据...",
        loading: false,
        singleSelect: false,
        selected: [],
        itemsCount: 10,
        headers: [
          {
            text: "用户名",
            align: "left",
            sortable: false,
            value: "userName",
          },
          { text: "用户类型", value: "userType" },
          { text: "真实姓名", value: "realName" },
          { text: "手机号码", value: "phone" },
          { text: "邮箱", value: "email" },
          { text: "微信", value: "wechat" },
          { text: "qq", value: "qq" },
          { text: "状态", value: "status" },
          { text: "操作", value: "action" },
        ],
        footer: {
          itemsPerPageOptions: [5, 10, 20, 50, 100],
          itemsPerPageText: "分页数量",
          showCurrentPage: true,
        },
        pagination: {},
        desserts: [],
      },
    };
  },
  mounted() {
    // Create a FormValidation instance
    let userForm = KTUtil.getById("userForm");

    this.fv = formValidation(userForm, {
      fields: {
        realName: {
          validators: {
            notEmpty: {
              message: "真实姓名不能为空!",
            },
          },
        },
        password: {
          validators: {
            stringLength: {
              min: 8,
              message: "密码长度不能小于8",
            },
          },
        },
        userType: {
          validators: {
            notEmpty: {
              message: "用户类型必须选择!",
            },
          },
        },
        phone: {
          validators: {
            notEmpty: {
              message: "手机号码不能为空!",
            },
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        bootstrap: new Bootstrap(),
      },
    });
  },
  created() {},
  methods: {
    create() {
      this.submitUrl = "/api/user/manage/create";
      this.formModel = {};

      this.fv.addField("userName", {
        validators: {
          notEmpty: {
            message: "用户名不能为空!",
          },
          remote: {
            message: "此用户名已存在,不可用!",
            method: "POST",
            url: "/api/user/exist",
          },
        },
      });
      this.$refs["my-modal"].show();
    },
    edit(userId = undefined) {
      userId = userId == undefined ? this.getSelectedIds() : [userId];
      if (userId.length != 1) {
        Swal.fire({
          title: "",
          text: "请选择要编辑的数据行,一次仅且只能选择一条!",
          icon: "warning",
          confirmButtonClass: "btn btn-success",
          heightAuto: false,
        });
        return;
      }

      let that = this;
      this.submitUrl = "/api/user/manage/update";
      this.fv.addField("userName", {});
      this.fv.removeField("userName");
      ApiService.query("/api/user/manage/query_by_id", {
        params: {
          userId: userId[0],
        },
      }).then(({ data }) => {
        that.formModel = data.result;
        that.roleSelected.splice(0, that.roleSelected.length);
        data.result.roles.forEach(function (item) {
          that.roleSelected.push(item.roleId);
        });
        that.$refs["roleSelect"].updateSelect(that.roleSelected);
      });

      this.$refs["my-modal"].show();
    },

    del(userId = undefined) {
      userId = userId == undefined ? this.getSelectedIds() : [userId];
      if (userId.length != 1) {
        Swal.fire({
          title: "",
          text: "请选择要删除的数据行!",
          icon: "warning",
          confirmButtonClass: "btn btn-success",
          heightAuto: false,
        });
        return;
      }

      let that = this;
      Swal.fire({
        title: "确定删除?",
        icon: "warning",
        buttons: true,
        showConfirmButton: true,
        confirmButtonText: "确定",
        showCancelButton: true,
        cancelButtonText: "取消",
        dangerMode: true,
      }).then((confirm) => {
        if (confirm.isConfirmed) {
          ApiService.post("/api/user/manage/delete", {
            userId: userId[0],
          }).then(({ data }) => {
            if (data.success) {
              that.getData();
              Swal.fire({
                title: "",
                text: "删除成功!",
                icon: "success",
                confirmButtonClass: "btn btn-success",
                heightAuto: false,
              });
            } else {
              Swal.fire({
                title: "删除失败！",
                text: data.message,
                icon: "warning",
                confirmButtonClass: "btn btn-success",
                heightAuto: false,
                timer: 3000,
              });
            }
          });
        }
      });
    },
    changeStatus(userId, status) {
      let that = this;
      ApiService.post("/api/user/manage/update", {
        userId: userId,
        status: status,
      }).then(({ data }) => {
        if (data.success) {
          Swal.fire({
            title: "",
            text: "保存成功！",
            icon: "success",
            confirmButtonClass: "btn btn-success",
            heightAuto: false,
            timer: 1500,
          });
          that.getData();
        } else {
          Swal.fire({
            title: "保存失败！",
            text: data.message,
            icon: "warning",
            confirmButtonClass: "btn btn-success",
            heightAuto: false,
            timer: 3000,
          });
        }
      });
    },
    resetPassword(userId) {
      ApiService.post("/api/user/manage/reset_password", {
        userId: userId,
      }).then(({ data }) => {
        if (data.success) {
          Swal.fire({
            title: "",
            text: "重置成功！",
            icon: "success",
            confirmButtonClass: "btn btn-success",
            heightAuto: false,
            timer: 1500,
          });
        } else {
          Swal.fire({
            title: "重置失败！",
            text: data.message,
            icon: "warning",
            confirmButtonClass: "btn btn-success",
            heightAuto: false,
            timer: 3000,
          });
        }
      });
    },
    getData() {
      let params = {
        pageNum: this.table.pagination.page,
        pageSize: this.table.pagination.itemsPerPage,
        userType: this.filter.userType,
        status: this.filter.status,
      };
      params["" + this.filter.field + ""] = this.filter.keyword;

      ApiService.query("/api/user/manage/query_list", {
        params: params,
      }).then(({ data }) => {
        this.table.desserts = data.result.data;
        this.table.itemsCount = data.result.total;
      });
    },
    getSelectedIds() {
      let ids = new Array();
      if (this.table.selected != null && this.table.selected != undefined) {
        this.table.selected.forEach(function (item) {
          ids.push(item.userId);
        });
      }
      return ids;
    },

    getUserTypeLabel(typeId) {
      const found = this.userType.find((type) => {
        return typeId === type.value;
      });
    },

    onSubmit() {
      let that = this;
      this.fv.validate().then(function (status) {
        if (status === "Valid") {
          ApiService.post(that.submitUrl, that.formModel).then(({ data }) => {
            if (data.success) {
              Swal.fire({
                title: "",
                text: "保存成功！",
                icon: "success",
                confirmButtonClass: "btn btn-success",
                heightAuto: false,
                timer: 1500,
              });
              that.getData();
              that.$refs["my-modal"].hide();
            } else {
              Swal.fire({
                title: "保存失败！",
                text: data.message,
                icon: "warning",
                confirmButtonClass: "btn btn-success",
                heightAuto: false,
                timer: 3000,
              });
            }
          });
        }
      });
    },
  },
  computed: {
    pagination: function () {
      return this.table.pagination;
    },
  },
  watch: {
    pagination: {
      handler() {
        this.getData();
      },
      deep: true,
    },

    roleSelected: {
      handler(newVal, oldVal) {
        if (newVal != null && newVal != undefined && newVal.length > 0) {
          let roles = new Array();
          newVal.forEach(function (item) {
            roles.push({ roleId: item });
          });
          this.formModel.roles = roles;
        }
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss">
.custom-v-dropdown {
  &.dropdown-toggle {
    padding: 0;

    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  &.dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;

    .b-dropdown-text {
      padding: 0;
    }
  }
}
</style>
